.cards-container {

    @include xy-grid;

    .card {
        @include breakpoint(small) {
            @include xy-cell(12);

            .card-content {
                padding: 1rem;
            }
        }

        @include breakpoint(medium) {
            @include xy-cell(1 of 3, true, 0.7rem );
        }
    }
}

.stand-out-card {
    background: $white;
    padding: 30px;
    box-shadow: 0 4px 8px $grey;
    border-radius: 5px;
}

.aside-card {
    background: $navy;
    border-radius: 5px;
    box-shadow: 0 4px 8px $grey;
    padding: 30px;

    h4 {
        color: $white;
        margin-top: 0;
    }

    p {
        color: $white;

        a {
            color: $white;
            text-decoration: underline;
        }
    }
}