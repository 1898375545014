* {
    &::-moz-selection { background: $blue; color: $white; }
    &::selection { background: $blue; color: $white; }
}

.hide {
    display: none;
}

.noscroll {
    overflow: hidden;
}


a, button {
    transition: $smooth;
}

.relative-column {
    position: relative;
}


.headingContainer {
    margin: 0 auto;
    display: block;
    width: max-content;
    max-width: 100%;
}

body {
    overflow-x: hidden !important;
    counter-reset: section;
}


.letsTalk {
    display: block;
    clear: both;
    padding: 120px 0 240px;

    h2 {
        font-family: $fontHeading;
        font-weight: $semibold;
        font-size: 3.6rem;
        text-align: center;
    }

    span {
        text-align: center; 
        font-family: $fontBody;
        font-size: 1.2rem;
        display: block;
        clear: both;
    }

    .btn {
        margin: 60px auto 0;
        display: table !important;
        float: none;
    }
}

.container-404 {
    margin-top: 60px;
    p {
        color: $black;
        font-family: $fontHeading;
        font-size: 24px;
    }
}