.faqs-container {
    position: relative;
    margin-top: 90px;

    h2 {
        color: $navy;
        font-size: 42px;
        line-height: 48px;
        margin-top: 0;
    }

    .faqs {
        position: relative;
        list-style-type: none;
        margin: 0;
        padding: 0;
        background: none;
        width: 100%;
 
        .faq {
            position: relative;
            background: $white;
            padding: 30px 30px;
            box-shadow: 0 4px 8px $grey;
            border-radius: 5px;
 
            .inner {
                overflow: hidden;
                display: none;
            }

            .faq-question {
                display: block;
                clear: both;

                h3 {
                    display: inline-block;
                    margin: 0;
                    font-size: 24px;
                }
                
                
            }

            .faq-open {
                position: absolute;
                max-height: 96px;
                height: 100%;
                width: 100%;
                z-index: 1;
                left: 0;
                top: 0;
                transition: $smooth;

                &:hover {
                    cursor: pointer;
                }

                &:before, &:after {
                    content: '';
                    position: absolute;
                    top: 40px;
                    height: 18px;
                    width: 2px;
                    border-radius: 10px;
                    background: $navy;
                    transition: $smooth;
                }

                &:before {
                    right: 38px;
                    transform: rotate(0);
                }
                &:after {
                    right: 38px;
                    transform: rotate(90deg);
                }

                &.active {
                    &:before {
                        transform: rotate(45deg);
                    }
                    &:after {
                        transform: rotate(135deg);
                    }
                }
            }
        }

        .faq + .faq {
            margin-top: 30px;
        }
    }
}

.faqs-info {
    margin-top: 60px;
    
    @media screen and (min-width: 40em) {
        margin-top: 220px;
    }
}