header {
    padding: 0;
    position: absolute;
    width: 100%;
    min-height: 60px;
    z-index: 111;
    transition: $smooth;

    #logo + #mainNavigation {
        margin-left: 60px;
    }

    #logo {
        float: left;
        position: relative;
        z-index: 1111;
        top: 30px;
        transition: $menuTransition;

        svg {
            position: relative;
            width: 150px; 
            float: left;
            z-index: 1111;
            transition: $menuTransition;

            .cls-2 {
                transition: $menuTransition;
                fill: $blue;
            }
 
            .cls-1 {
                transition: $menuTransition;
                fill: $white;
                font-size: 108.44px;
                font-family:Lato-Black, Lato;
                font-weight:800;
                letter-spacing:0.09em;
                opacity: 1;
            }
        }
    }

  
    // Tablet -> Desktop
    @media screen and (min-width: 54em) {
        &.sticky {
            transition: $smooth;
            position: fixed;
            top: 0;
            width: 100%;
            height: 60px; 
            background: rgba($white, 0.96); 
            z-index: 111111;
            box-shadow: 0 2px 4px $grey;

            #logo {
                top: 10px;
                position: relative;
                svg {
                    position: relative;
                    width: 120px; 
                    float: left;
                    z-index: 1111;
                    transition: $menuTransition;
        
                    .cls-2 {
                        transition: $menuTransition;
                        fill: $navyDark;
                    }
        
                    .cls-1 {
                        fill: $navy;
                    }
                }
            }

            #mainNavigation {
                
                // Tablet -> Desktop
                    display: inline-block;
                    z-index: 1111;
            
                    .menu-main-navigation-container {
                        margin-top: 0;
                    }
                
            }
        }
    }
        
    
}