.breadcrumbs {
    position: relative;
    z-index: 1;
    color: $white;
    padding-left: 5px;

    span {
        a {
            color: $white;
            font-size: 12px;
            text-transform: uppercase;
            text-decoration: none;
            transition: $smooth;

            &.current-item {
                color: $yellow;
            }
     
            &:hover {
                color: $yellow;
            }
        }
    }
}