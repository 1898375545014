.featured-events {
    position: relative;

    .event-item {
        height: 360px;
        margin-top: 30px;;

        .featured-event {
            position: relative;
            background-size: cover;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            border-radius: 5px;
            height: 100%;
            overflow: hidden;
            margin-bottom: 45px;
            cursor: pointer;
            box-shadow: 0 4px 8px $grey;
            transition: $smooth;

            .event-bg {
                float: none;
                display: block;
                clear: both;
                width: 100%;
                background-size: cover;
                height: 35%;
                position: relative;
                transition: $smooth;
                
                &:before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: rgba($white, 0);
                    top: 0;
                    left: 0;   
                    transition: $smooth;             
                }
            } 

            .event-content {
                background: $white;
                position: relative;
                width: 100%;
                height: 65%;
                display: block;
                clear: both;
                padding: 15px 30px 30px;
                
                h3 {
                    font-size: 18px;
                    line-height: 24px;
                    line-height: 27px;
                    color: $navy;
                    margin: 0;

                    a {
                        color: $navy;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }   
    
                .event-categories {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
        
                    li {
                        display: inline-block;
                        color: $blue;
                        font-family: $fontHeading;
                        text-transform: uppercase;
                        font-size: 12px;
                    }
        
                    li + li {
                        margin-left: 10px;
                    }
                }

                .event-datetime {
                    position: absolute;
                    bottom: 90px;
                    width: 50%;
                    left: 30px;

                    i {
                        color: $greyDark;
                        margin-bottom: 5px;
                        font-size: 12px;
                    }

                    span {
                        display: block;
                        clear: both;
                        color: $greyDark;
                        font-size: 12px;
                        line-height: 14px;
                    }
                }

                .event-location {
                    position: absolute;
                    bottom: 90px;
                    width: 50%;
                    left: 50%;
                    padding-right: 30px; 


                    i {
                        color: $greyDark;
                        margin-bottom: 5px;
                        font-size: 12px;
                    }

                    span {
                        display: block;
                        clear: both;
                        color: $greyDark;
                        font-size: 12px;
                        line-height: 14px;

                        a {
                            color: $greyDark;
                            text-decoration: none;
                        }
                    }

                    span + span {
                        a {
                            color: $greyDark;
                            text-decoration: underline;

                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }

                a.btn {
                    position: absolute;
                    bottom: 30px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    width: calc(100% - 60px);
                    display: block;
                    clear: both;
                    padding: 7px 20px;
                    font-size: 15px;
                    border-radius: 5px;
                    text-align: center;
                    font-weight: $normal;
                }
            }

            &:hover {
                .event-bg {
                    &:before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background: rgba($white, 0.3);
                        top: 0;
                        left: 0;
                    }
                }
            }
        }

        &:first-child {
            // Tablet -> Desktop
            @media screen and (min-width: 64em) {
                width: 66.66667%;

                .featured-event {
                    height: 100%;
                    .event-bg {
                        float: left;
                        width: 48%;
                        background-size: cover;
                        height: 100%;
                        clear: none;
                    }
        
                    .event-content {
                        float: right;
                        height: 100%;
                        width: 52%;
                        clear: none;

                        .event-title {
                            min-height: 150px;
                            
                            h3 {
                                margin-top: 15px;
                                font-size: 39px;
                                line-height: 42px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Dundonald Bible Week
.dbw-timetable {
    position: relative;

    .timetable-days {
        position: relative;
        list-style-type: none;
        margin: 0;
        padding: 0;
        background: none;
        width: 100%;
 
        .timetable-day {
            position: relative;
            background: $white;
            padding: 30px 30px;
            box-shadow: 0 4px 8px $grey;
            border-radius: 5px;
 
            .inner {
                overflow: hidden;
                display: none;
            }

            .day-details {
                display: block;
                clear: both;

                h3 {
                    display: inline-block;
                    margin: 0;
                    font-size: 36px;
                }
                
                .timetable-day-date {
                    display: inline-block;
                    position: relative;
                    top: -8px;
                    margin-left: 15px;
                    color: $blue;
                }
            }

            .timetable-day-open {
                position: absolute;
                max-height: 96px;
                height: 100%;
                width: 100%;
                z-index: 1;
                left: 0;
                top: 0;
                transition: $smooth;

                &:hover {
                    cursor: pointer;
                }

                &:before, &:after {
                    content: '';
                    position: absolute;
                    top: 40px;
                    height: 18px;
                    width: 2px;
                    border-radius: 10px;
                    background: $navy;
                    transition: $smooth;
                }

                &:before {
                    right: 38px;
                    transform: rotate(0);
                }
                &:after {
                    right: 38px;
                    transform: rotate(90deg);
                }

                &.active {
                    &:before {
                        transform: rotate(45deg);
                    }
                    &:after {
                        transform: rotate(135deg);
                    }
                }
            }

            .timetable-day-events {
                position: relative; 
                padding: 0;
                list-style-type: none;

                .timetable-day-event {
                    position: relative;
                    padding-top: 45px;

                    .event-time {
                        font-family: $fontHeading;
                        font-weight: $semibold;
                        font-size: 18px;
                    }

                    h2 {
                        font-size: 30px;
                        line-height: 33px;
                        margin: 5px 0 0 0;
                        font-family: $fontHeading;

                        a {
                            color: $navy;
                            text-decoration: underline;
                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }

                    .event-location {
                        position: relative;
                        margin-top: 15px;
    
                        i {
                            color: $greyDark;
                            margin-bottom: 5px;
                            font-size: 12px;
                        }
    
                        span {
                            display: block;
                            clear: both;
                            color: $greyDark;
                            font-size: 12px;
                            line-height: 14px;
    
                            a {
                                color: $greyDark;
                                text-decoration: none;
                            }
                        }
    
                        span + span {
                            a {
                                color: $greyDark;
                                text-decoration: underline;
    
                                &:hover {
                                    text-decoration: none;
                                }
                            }
                        }
                    }

                    &:first-child {
                        padding-top: 60px;
                    }

                    &:last-child {
                        padding-bottom: 30px;
                    }
                }

                .timetable-day-event + .timetable-day-event {
                    margin-top: 45px;
                    border-top: 1px solid $grey;
                }
            }
        }

        .timetable-day + .timetable-day {
            margin-top: 30px;
        }
    }
}
  