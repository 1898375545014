.speaker-info-container {
    margin-top: 60px;

    // Tablet -> Desktop
    @media screen and (min-width: 54em) {
        margin-top: 90px;
    }

    h2 {
        color: $navy;
        font-size: 42px;
        line-height: 48px;
    }

    .speaker-info {
        position: relative;
        width: 100%;
        margin-top: 120px;

        // Tablet -> Desktop
        @media screen and (min-width: 54em) {
            margin-top: 0;
        }

        .speaker-image {
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            top: -60px;
            border-radius: 100%;
            width: 120px;
            height: 120px;
            background-size: cover;

            // Tablet -> Desktop
            @media screen and (min-width: 54em) {
                left: -90px;
                margin: 0;
                right: auto;
                width: 180px;
                height: 180px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .speaker-content {
            background: $white;
            padding: 60px 20px 30px 20px;

            // Tablet -> Desktop
            @media screen and (min-width: 54em) {
                padding: 45px 30px 30px 120px;

                h3 {
                    margin-top: 45px;
                }
            }

            h3 {
                margin: 0;
            }

            p {
                margin-top: 15px;

                a {
                    color: $blue;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}