

h1 {
    font-family: $fontHeading;
    font-weight: $bold;
    color: $white;
    font-size: 6rem;
    line-height: 5.8rem;
}

h2 {
    font-family: $fontHeading;
    color: $white;
    font-size: 6rem;
    line-height: 5.8rem; 
}

h3 {
    font-family: $fontHeading;
    font-weight: $bold;
    text-align: left;
    color: $black;
    font-size: 1.8rem;
    line-height: 2.1rem;
    position: relative;
}

h4 {
    font-family: $fontHeading;
    font-weight: $semibold;
    text-align: left;
    color: $black;
    font-size: 1.2rem;
    line-height: 1.5rem;
    position: relative;
    margin-top: 45px;
}

h5 {
    font-family: $fontHeading;
    margin-bottom: .5rem;
}

h6 {
    text-transform: uppercase;
    font-family: $fontHeading;
    font-size: 0.9rem;
}

p {
    font-size: 1rem;
    line-height: 1.2rem;
    color: $black;
    font-family: $fontBody;

    &.intro {
        color: $white;
    }
}

p + .btn {
    margin-top: 20px;
    display: table-cell;
}

// Title Classes
.introHeading {
    font-family: $fontHeading;
    font-weight: $semibold;
    text-align: left;
    color: $black;
    font-size: 2.1rem;
    line-height: 2.4rem;
    position: relative;
    margin-top: 120px;
    margin-bottom: 30px;

    @media screen and (min-width: 40em) {
        margin-top: 240px;
        margin-bottom: 180px;
    }

    &:before {
        content: "";
        position: absolute;
        top: -15px;
        width: 45px;
        height: 6px;
        background: $blue;
        left: 0; 
        margin: 0 auto;
    }

    &.noBar {
        &:before {
            display: none;
        }
    }
}
 
.pageIntro {
    font-family: $fontHeading;
    font-weight: $semibold;
    text-align: left;
    color: $black;
    font-size: 2.1rem;
    line-height: 2.4rem;
    position: relative;
    margin-top: 120px;
    margin-bottom: 90px;

    @media screen and (min-width: 40em) {
        margin-top: 120px;
        margin-bottom: 90px; 
    }

    &:before {
        content: "";
        position: absolute;
        top: -15px;
        width: 45px;
        height: 6px;
        background: $blue;
        left: 0; 
        margin: 0 auto;
    }
}