.main-container {
    @include xy-grid-container;
}

.main-grid {
    @include xy-grid();
    margin-top: 1rem;

    @include breakpoint(medium) {
        margin-top: 3rem;
    }

    &.sidebar-left {
        // Place the sidebar below main content on small screens ...
        @include breakpoint(small) {
            .main-content { order: 1; }
            .sidebar { order: 2; }
        }

        // ... and to the left on medium-up screens, when using the sidebar-left template
        @include breakpoint(medium) {
            .main-content { order: 2; }
            .sidebar { order: 1; }
        }
    }

    // Full width template
    .main-content-full-width {
        @include xy-cell(12);
    }

    // Default template
    .main-content {
        @include breakpoint(small) {
            @include xy-cell(12);
        }

        @include breakpoint(medium) {
            @include xy-cell(8);
        }
    }

    .sidebar {
        @include breakpoint(small) {
            @include xy-cell(12);
        }

        @include breakpoint(medium) {
            @include xy-cell(4);
            margin-top: 1rem;
        }
    }
}


.wysiwyg-content {
    p {
        color: $black;

        a {
            color: $blue;
            text-decoration: underline;
        }
    }
}

.introduction-content {
    margin-top: 90px;
    margin-bottom: 60px;
    h2 {
        color: $navy;
        font-size: 42px;
        line-height: 48px;
    }

    .introImg {
        width: 100%;
        height: 300px;
        background-position: 50% 50%;
        background-size: cover;
        box-shadow: 0 4px 8px #E5E5E5;
        border-radius: 5px;
    }
}
