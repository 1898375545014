#mainNavigation {
    display: none;
    
    // Tablet -> Desktop
    @media screen and (min-width: 54em) {
        display: inline-block;
        z-index: 1111;

        .menu-main-navigation-container {
            margin-top: 20px;
            ul {
                float: right;
                margin: 0;
                padding: 0;
                list-style-type: none;

                li + li {
                    margin-left: 20px;
                }
        
                li {
                    display: inline-block;
                    position: relative;
                    margin: 0;
                    padding: 0;
                    text-align: left;
                    font-size: 1rem;
                    font-family: $fontHeading;
                    transition: $smooth;
        
                    a {
                        display: block;
                        color: $white;
                        padding: 10px 15px;
                        position: relative;
                        font-weight: $normal;
                        text-decoration: none;
        
                        &:hover {
                            cursor: pointer;
                        }
                    }

                    &.is-active {
                        a:before {
                            opacity: 1;
                        }
                    }


                    ul.sub-menu {
                        display: none;
                        position: absolute;
                        top: 100%;
                        left: 0;
                        background: $white;
                    }

                    &:hover {
                        &:before {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            height: 4px;
                            width: 100%;
                            background: $yellow;
                            left: 0;
                            right: 0;
                        }

                        ul.sub-menu {
                            display: block;

                            li {
                                position: relative;
                                width: 200px;
                                background: $white;
                                display: block;
                                clear: both;
                                height: 45px;

                                &:hover {
                                    &:before {
                                        display: none;
                                    }
                                }
                                
                                a {
                                    position: absolute;
                                    padding: 10px 15px;
                                    width: 100%;
                                    color: $navy;
                                    transition: $smooth;
    
                                    &:hover {
                                        background: $grey;
                                    }

                                    &:before {
                                        display: none;
                                    }
                                }
                            }

                            li + li {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.mobileMenu-wrapper {
    ul {
        list-style-type: none;
        padding: 0;
        
        li {
            a {
                color: $white;
                font-size: 24px;
                text-decoration: none;
                font-family: $fontHeading;
                font-weight: $semibold;
            }
        }

        li + li {
            margin-top: 5px;
        }
    }
}

.rightNav {
    float: right;
}

// Menu Icon
.menuIcon-container {
    display: inline-block;
    top: 45px;
    position: relative;
    z-index: 1111;

    .menuLink {
        cursor: pointer;
    }

    #menuTitle {
        font-family: $fontHeading;
        color: $white;
        font-weight: $normal;
        display: inline-block;
        padding-right: 15px;
        transition: $smooth;
    }

    .menuIcon {
        width: 15px;
        position: relative;
        transform: translateY(-50%);
        transition: .5s ease-in-out;
        cursor: pointer;
        margin-top: 8px;
        float: right;
    
        span {
            display: block;
            position: absolute;
            height: 2px;
            width: 15px;
            background: $white;
            border-radius: 5px;
            opacity: 1;
            right: 0;
            transform: rotate(0deg);
            transition: $smooth;
    
            &:nth-child(1) {
            top: 0px;
            }
    
            &:nth-child(2) {
                top: 8px;
            }
        }
    }

    &:hover {
        .menuIcon {
            span {
                &:nth-child(1) {
                    top: 4px;
                }
                &:nth-child(2) {
                    top: 4px;
                }
            }
        }
    }
    
    .menuIcon.open {
        span:nth-child(1) {
            transform: rotate(45deg);
            top: 4px;
        } 
    
        span:nth-child(2) {
            transform: rotate(-45deg);
            top: 4px;
        }
    }

    // Tablet -> Desktop
    @media screen and (min-width: 54em) {
        display: none;
    }
}

#mobileNavigation {
    padding: 45px 0;
    position: absolute;
    width: 0;
    height: 100vh;
    top: 0;
    right: -100%;
    background: $navy;
    transition: $menuTransition;
    z-index: 11;
    overflow-y: scroll;

    .row {
        max-width: 75rem;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .mobileMenu-wrapper {
        position: relative;
        margin: 0 auto;
        display: none;
        margin-top: 120px;
        animation-duration: 1s;
        animation-delay: .5s;


        .menu-mobile-container { 
            ul {
                list-style-type: none;
                padding: 0;

                li + li {
                    margin-top: 15px;

                    @media screen and (min-width: 40em) {
                        margin-top: 30px;
                    }
                }

                li {
                    a {
                        font-family: $fontHeading;
                        color: $white;
                        font-size: 1.8rem;
                        font-weight: $bold;
                        position: relative;
                        text-decoration: none;

                        @media screen and (min-width: 40em) {
                            font-size: 2.4rem;
                        }

                        &:before {
                            content: '';
                            position: absolute;
                            width: 30px;
                            height: 6px;
                            background: $red;
                            transition: $smooth;
                            top: 75%;
                            left: -45px;
                            opacity: 0;
                        }

                        &:hover {
                            text-decoration: none;
                            &:before {
                                opacity: 1;
                                left: 0;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .mobileMenu-contactDetails {
        margin-top: 45px;
        display: none;
        animation-duration: 1s;
        animation-delay: 1s;

        @media screen and (min-width: 40em) {
            float: right;
            margin-top: 180px;
        }

        ul { 
            list-style-type: none;
            padding: 0;

            span {
                font-weight: $semibold;
                font-family: $fontHeading;
                font-size: 1rem;
                color: $white;
            }

            span + li {
                margin-top: 10px;
            }

            li + li {
                margin-top: 5px;
            }

            li {
                font-size: 1rem;
                font-family: $fontHeading;

                a {
                    color: $white;
                    border-bottom: 0 solid $red;
                    transition: $smooth;
                    padding-bottom: 0;
                    text-decoration: none;

                    &:hover {
                        border-bottom: 2px solid $red;
                    }
                }
            }
        }

        #copyright {
            color: $white;
            font-size: .8rem;
            padding-top: 30px;
            opacity: .6;
            display: block;
        }
    }
}

.open {
    #mobileNavigation {
        right: 0;
        width: 100%;

        .mobileMenu-wrapper {
            display: block;
        }
    }

    .mobileMenu-contactDetails { 
        display: block !important;
    }
}


body.open {
    header {
        .rightNav {

            #menuTitle {
                color: $white;
            }

            .menuIcon {
                span {
                    background: $white;
                }
            }
        }
    }
}

header.sticky {
    #mainNavigation {
        
        // Tablet -> Desktop
        @media screen and (min-width: 54em) {
            .menu-main-navigation-container {
                ul {    
                    li {
            
                        a {
                            display: block;
                            color: $navy;
                            padding: 20px 0 0; 
                            position: relative;
                            font-weight: $normal;
                            text-decoration: none;
            
                            &:before {
                                content: '';
                                position: absolute;
                                bottom: 0;
                                width: 100%;
                                height: 4px;
                                background: $blue; 
                                transition: $smooth;
                                opacity: 0;
                            }
            
                            &:hover {
                                cursor: pointer;
                                
                                &:before {
                                    opacity: 1;
                                }
                            }
                        }
    
                        &.is-active {
                            a:before {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}