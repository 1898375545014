// Fix the issue where the WP admin-bar overlaps the mobile menu
#wpadminbar {
    position: fixed !important;
}

// Make sure that the WP admin-bar does not overlap Foundation components
body.admin-bar {
    // Offset sticky top bar
    &.f-topbar-fixed {
        .sticky.fixed {
            margin-top: rem-calc(32);
        }
    }
    
    // Offset mobile off-canvas menu
    &.offcanvas {
        .off-canvas.is-open {
            top: rem-calc(46);
            @include breakpoint(783) {
                top: rem-calc(32);
            }
        }
    }
}
