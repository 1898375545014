.heading-banner {
    position: relative;
    padding: 120px 0;
    overflow: hidden;
    background-size: cover;
    background-position: 50% 50%;

    @media screen and (min-width: 40em) {
        padding: 200px 0 180px;
    }

    &:before { 
        content: '';
        background: $navy;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        opacity: 0.9;
    }
    
    h2 {
        font-family: $fontHeading;
        font-weight: $bold;
        font-size: 3.3rem;
        line-height: 3.6rem; 
        margin: 0;
        z-index: 11;
        position: relative;
        text-align: center;

        @media screen and (min-width: 40em) {
            font-size: 5.4rem;
            line-height: 5.5rem;
        }
    }

    p {
        color: $yellow;
        font-family: $fontHeading;
        font-size: 1.1rem;
        line-height: 1.2rem;
        z-index: 11;
        position: relative;
        text-align: center;

        @media screen and (min-width: 40em) {
            font-size: 1.8rem;
            line-height: 1.85rem;
        }
    }


    h2, .btn {
        animation-duration: 1s;
        animation-delay: .3s;
    }

}