// Colours 
$white: #FFFFFF;
$black: #000000;
$grey: #E5E5E5; 
$greyLight: #F8F8F8;
$red: #EA4848;
$blue: #61D8FB;
$blueDark: #33a1c1; 
$navy: #0E2E43;
$navyDark: #041427; 
$yellow: #F5C343;

$greyDark: #95989C;
$lilac: #F6F7FB;  

$dark-nav-color: #2C3840;
$greyDark: #95989C;
$lilac: #F6F7FB;
