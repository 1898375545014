// Fonts
$fontHeading: 'Lato', sans-serif;
$fontBody: 'nunito', sans-serif; 

// Font Weights
$bold: 700; 
$semibold: 600;
$medium: 500;
$normal: 400;
$light: 300;
$thin: 100;
 
// Font Styles
$italic: italic;

// Transitions
$smooth: .2s ease-in-out;
$menuTransition: .6s ease-in-out;




body {
    background: $greyLight !important;
} 


// Responsive Media Queries

/* Small only */
@media screen and (max-width: 39.9375em) {}

/* Medium and up */
@media screen and (min-width: 40em) {}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {}

/* Large and up */
@media screen and (min-width: 64em) {}

/* Large only */
@media screen and (min-width: 64em) and (max-width: 74.9375em) {}