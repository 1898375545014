.wpcf7 {

    .wpcf7-form {
        transition: $smooth;
        opacity: 1;
        height: auto;
        overflow: hidden;

        p {
            margin: 20px 0 0;
        }

        label {
            color: $navy;
            font-family: $fontHeading;
            font-size: 16px;
            font-weight: $normal;
            margin-top: 10px;
    
            span {
                display: block;
                padding-top: 5px;
                color: $black;
    
                input[type="text"], input[type="tel"], input[type="email"], select, textarea {
                    border: 1px solid $grey;
                    background: $white;
                    width: 100%;
                    font-size: 15px;
                    height: 40px;
                    padding-left: 15px;
                    border-radius: 0;
                    -webkit-appearance: none;
                    -webkit-border-radius: 0px;
                    color: $black;
                }

                select {
                    background-image:
                    linear-gradient(45deg, transparent 50%, gray 50%),
                    linear-gradient(135deg, gray 50%, transparent 50%),
                    linear-gradient(to right, #ccc, #ccc);
                    background-position:
                    calc(100% - 20px) calc(1em + 2px),
                    calc(100% - 15px) calc(1em + 2px),
                    calc(100% - 2.5em) 0.5em;
                    background-size:
                    5px 5px,
                    5px 5px,
                    1px 1.5em;
                    background-repeat: no-repeat;
                    
                    &:focus {
                        background-image:
                        linear-gradient(45deg, green 50%, transparent 50%),
                        linear-gradient(135deg, transparent 50%, green 50%),
                        linear-gradient(to right, #ccc, #ccc);
                        background-position:
                        calc(100% - 15px) 1em,
                        calc(100% - 20px) 1em,
                        calc(100% - 2.5em) 0.5em;
                        background-size:
                        5px 5px,
                        5px 5px,
                        1px 1.5em;
                        background-repeat: no-repeat;
                        border-color: green;
                        outline: 0;
                    }
                }

                textarea {
                    height: 150px;
                }
            }
        }

        input[type="submit"] {
            background: $navy;
            color: $white;
            font-family: $fontHeading;
            padding: 15px 60px;
            font-weight: $normal;
            font-size: 1.1rem;
            border-radius: 5px;
            text-decoration: none;
            transition: $smooth;
            position: relative;
            outline: none; 
            border: 0;
            cursor: pointer;

            &:hover {
                opacity: .85;
                transform: scale(0.95);
            }
        }
    }

    .wpcf7-list-item {
        margin-top: 5px;
        &.first {
            margin-left: 0;
        }

        input[type="radio"] {
            color: $white;
        }

        span {
            color: $white;
        }
    }

    .wpcf7-mail-sent-ok {
        background: $yellow;
        color: $white;
        border: none !important;
        padding: 15px 10px;
        font-weight: $semibold; 
    }

    .wpcf7-not-valid {
        border: 2px solid #d41b1b;
    }

    .wpcf7-validation-errors {
        background-color: #d41b1b;
        color: $white;
        padding: 10px 15px;
        border: 0;
        font-family: $fontHeading;
        margin: 20px 0 0;
    }

    .wpcf7-not-valid-tip {
        padding: 10px 15px; 
        background:#d41b1b;
        color: $white !important;
        font-family: $fontHeading;
    }

    span[role="alert"] {
        color: $red;
        margin-top: 5px;
        font-family: $fontHeading;
        font-weight: $normal;
        font-size: 14px;
    }
}