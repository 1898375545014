.btn {
    font-size: 15px;
    padding: 15px 20px;
    margin-top: 30px;
    text-decoration: none;
    border-radius: 5px;
    font-weight: $bold;
    transition: $smooth;
    position: relative;
    transform: scale(1);
    -webkit-transform:scale(1.0);
    &.blueBg {
        background: $blue;
        color: $navy; 

        &:hover {
            background: $blueDark;
        }
    }

    &.navyBg {
        background: $navy;
        color: $white;

        &:hover {
            background: $navyDark;
        }
    }

    &:hover {
        transform: scale(0.95); 
        -webkit-transform:scale(0.95);
    }
}