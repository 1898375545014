footer {
    padding: 45px 0;
    background: $navyDark;
    margin-top: 150px;

    #logoAlt {
        position: relative;
        width: 105px;
        float: left;

        svg {
            position: relative;
            width: 120px; 
            float: left;
            z-index: 1111;
            transition: $menuTransition;

            .cls-2 {
                transition: $menuTransition;
                fill: $blue;
            }
 
            .cls-1 {
                transition: $menuTransition;
                fill: $white;
                font-size: 108.44px;
                font-family:Lato-Black, Lato;
                font-weight:800;
                letter-spacing:0.09em;
                opacity: 1;
            }
        }
    }

    #copyright {
        display: block;
        clear: both;
        font-family: $fontHeading;
        color: $white;
        font-size: .8rem;
        top: 15px;
        position: relative;
    }

    .footerNavigation {
        ul {
            list-style-type: none;
            margin: 0;
            float: left;
            text-align: left;
            display: block;
            clear: both;
            margin-top: 45px;
            padding-left: 0;

            @media screen and (min-width: 40em) {
                float: right;
                text-align: right;
                margin-top: 10px;
            }

            li {
                font-family: $fontHeading;
                font-size: 1.2rem;
                color: $white;
                display: block;

                @media screen and (min-width: 40em) {
                    display: inline-block;
                }

                a {
                    color: $white;
                    text-decoration: none;
                    font-size: 1.2rem;
                    font-weight: $normal;
                    &:hover {
                        text-decoration: underline;
                        color: $yellow;
                    }
                }
            }

            li + li {
                margin-top: 15px;

                @media screen and (min-width: 40em) {
                    margin-top: 0;
                    margin-left: 30px;
                }
            }
        }
    }

    .socialNavigation {
        list-style-type: none;
        margin: 0;
        float: left;
        text-align: left;
        display: block;
        clear: both;
        margin-top: 45px;
        padding-left: 0;

        @media screen and (min-width: 40em) {
            float: right;
            text-align: right;
            margin-top: 10px;
        }

        li {
            font-family: $fontBody;
            font-size: .9rem;
            color: $greyDark;
            display: block;

            @media screen and (min-width: 40em) {
                display: inline-block;
            }

            a {
                color: $greyDark;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        li + li {
            margin-top: 15px;

            @media screen and (min-width: 40em) {
                margin-top: 0;
                margin-left: 30px;
            }
        }
    }

}

.get-in-touch {
    position: relative;
    display: inline-block;
    margin-top: 150px;
    color: $white;
    text-decoration: none;
    padding: 0 15px;
    transition: $smooth;
    top: 0;

    &:before {
        content: '';
        position: absolute;
        background: $yellow;
        width: 100%;
        height: 6px;
        bottom: 0;
        left: 0;
        right: 0;
        transition: $smooth;
    }

    &:hover {
        top: -12px;

        &:before {
            bottom: -9px;
        }

        h3 {
            color: $white;
        }

        h4 {
            color: $white;
        }
    }

    h3 {
        text-decoration: none;
        color: $white;
        transition: $smooth;
    }

    h4 {
        margin-top: 15px;
        color: $white;
        font-family: $fontBody;
        text-decoration: none;
        transition: $smooth;
    }

    i {
        color: $white;
        position: absolute;
        right: 10px;
        top: 33px;
        font-size: 24px;
    }
}