#homepageContainer {
    .heading-banner {
        // Tablet -> Desktop
        @media screen and (min-width: 54em) {
            height: 710px;
        }
    }
}


.linksContainer {
    position: relative;

    .first-full-width {
        &:first-child {
            width: 100% !important;
        }
    }

    &.church-links {
        .link-content-container {
            
        }
    }

    .link-content-container {
        position: relative;
        height: 400px;
        background-size: cover;
        border-radius: 5px;
        margin-top: 30px; 
        transition: $smooth;
        background-position: 50% 50%;
        
        &:before {
            content: '';
            position: absolute;
            background: rgb(14,46,67);
            background: linear-gradient(0deg, rgba(14,46,67,1) 15%, rgba(14,46,67,0.09707633053221287) 100%);
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0.75;
            border-radius: 5px;
        }
        a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 11;
        }

        .link-content {
            position: absolute;
            width: 100%;
            bottom: 30px;
            padding: 0 30px;
            pointer-events: none;
            
            h3 {
                font-size: $fontHeading;
                color: $white;
                font-size: 30px;
                margin: 0;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    top: -15px;
                    width: 0;
                    height: 3px;
                    background: $yellow;
                    left: 0;
                    transition: $smooth;
                }
            }

            p {
                color: $white;
                margin-top: 15px;
                

                // Tablet -> Desktop
                @media screen and (min-width: 54em) {
                    width: 75%;
                }
            }
        }

        &:hover {
            transition: $smooth;

            .link-content {
                h3 {
                    &:before {
                        width: 45px;
                    }
                }
            }
        }
    }
}
