#eventContainer {
    position: relative;

    .eventContent {
        padding-top: 90px;
        display: block;

        .event-content {
            box-shadow: 0 4px 8px $grey;
            background: $white;
            transition: $smooth;
            position: relative;
            border-radius: 5px;
            padding: 45px 30px 30px;
            margin-top: 30px;

            @media screen and (min-width: 40em) {
                margin-top: 0;
            }

            .breadcrumbs {
                span {
                    color: $navy;
                }
            }
        }
        

        .event-details {
            box-shadow: 0 4px 8px $grey;
            background: $white;
            transition: $smooth;
            position: relative;
            border-radius: 5px;
            padding: 45px 30px 30px;
            
            h4 {
                font-size: 21px;
                margin: 0;
                color: $navy;
                margin-top: 30px;
                
                &:first-child {
                    margin-top: 0;
                }
                
                i {
                    color: $blue;
                    margin-right: 5px;
                    font-size: 15px;
                    position: relative;
                    top: -2px;
                }
            }

            .event-location {
                margin-top: 10px;

                span {
                    display: block;
                    clear: both;
                    color: $black;
                    font-size: 15px; 
                    line-height: 14px;

                    a {
                        color: $navy;
                        text-decoration: none;
                    }
                }

                span + span {
                    font-size: 12px;
                    margin-top: 10px;
                    a {
                        color: $greyDark;
                        text-decoration: underline;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }

            .event-speaker {
                position: relative;
                margin-top: 10px;
 
                .event-speaker-image {
                    position: absolute;
                    width: 60px;
                    height: 60px;
                    background-size: cover;
                    background-position: 50% 50%;
                    background-repeat: no-repeat;
                    border-radius: 100%;
                    right: 30px;
                    top: -30px;
                }

                span {
                    display: block;
                    clear: both;
                    color: $black;
                    font-size: 15px; 
                    line-height: 14px;
                }

                span + span {
                    font-size: 12px;
                    margin-top: 10px;
                    a {
                        color: $greyDark;
                        text-decoration: underline;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}