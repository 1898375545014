.speakers-list-container {
    position: relative;
    background: $greyLight;
    padding: 60px 0;
    margin-top: 60px;

    .speakers-list-item {
        position: relative;
        width: 100%;
        box-shadow: 0 4px 8px $grey;
        margin-top: 30px;

        .speakers-list-item-image {
            height: 240px;
            width: 100%;
            position: relative;
            background-size: cover;
            background-position: 50% 50%;
            border-radius: 5px 5px 0 0;
        }

        .speakers-list-item-content {
            position: relative;
            padding: 15px 30px 30px;
            border-top: 0;
            background: $white;
            border-radius: 0 0 5px 5px;

            h3 {
                margin: 0;
                font-size: 21px;
                margin-bottom: 0;
            }

            span {
                color: $blue;
                // text-transform: uppercase;
                font-size: 14px;
                font-family: $fontHeading;
                font-weight: $medium;

            }

            p {

            }
        }
    }
}